var $ = require( 'jquery' )
var jQuery = $
global.$ = $; // rendre globale (on peut l'utiliser depuis la console F12)
global.jQuery = jQuery;
require( 'bootstrap3' );
var sclbasic = require('node-scl-basic')
var cca = require('../cca');
var Handlebars = require('handlebars');
var queryString = require('query-string');

// NOTE: html-loader is used through webpack config
// default is to get string - possibility to use directly handlebars
var priceTpl = require("../templates/price.tpl").default;
var registrationsClosed = require("../templates/registrationsClosed.tpl").default;
var responseTpl = require("../templates/response.tpl").default;
var toPaymentTpl = require("../templates/to_payment_msg.tpl").default;
var toFwdMessageTpl = require("../templates/fwd_msg.tpl").default;
var modalTpl = require("../templates/modal_content.tpl").default;
var schoolUserTpl = require("../templates/new_user_school_handling.tpl").default;


// webpack plugin
const Buffer = require('buffer/').Buffer;

/////// preprocessor config for build 
/////////////////////////////
/////////////////////////////////////////////////////
///////////////////////
///////////////////////////
var config = require('../../config.public.live.json');
// WTF do I need something from routes??
//const { use } = require('../../routes');
//////////


// init global vars
var chosenPayment;
var chosenService;
var chosenEnvoi;
var chosenEnvoiIntl;
var chosenRetard;
var itemHash;
var pricingData;
var currentFinalPrice;
var clickedFlag = false;
var schoolHandling=false;

var retard_facturation_id = "5eqvWcdGFk2xD00wCK9rRn"; 
var envoi_facturation_id = "5KuzcCfK9pexM0f8TUVpPS"; 
var envoi_intl_facturation_id = "4VXGFfsVh6E4z4XaDqFHQX"; 
var frais_produit_info_id = "7M1ZW7HGwa23COia3b4Vii"; 

var response_data = {};


Handlebars.registerHelper("formatNumber", function(number) {
    return number.toFixed(2); 
});

function padDigits(number, digits) {
        return Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number;
}

Handlebars.registerHelper("formatDate", function(TSint) {
    var date = new Date(TSint*1000);
    var day = date.getUTCDate();
    var month = date.getUTCMonth()+1;
    var year = date.getUTCFullYear();
    // formatted as dd/mm/yyyy
    var datetime = padDigits(day,2) + '/' + padDigits(month,2) + '/' + year;
    return datetime;
});


function run(){
    $( document ).ready(function(){
        $('.ajax_loading').hide();

        var query=queryString.parse(location.search);
        var services_url = '/proxy/services-list?pretty';
        //if(development){
        //    services_url = 'services-list-demo.json';
        //}
        $.ajax({
            type: 'GET',
            url: services_url,
            contentType:    'text/plain',
            error: function(){
                // borealis = no alert
                alert('Erreur Affichage');
            },
                // parse response and apply searchCallback if non empty
            success: function(dataReceived){
                // deserialize response
                //alert(JSON.stringify(dataReceived));

                chosenService = (dataReceived.service_list.filter(e => e.service_id==query.service_id))[0];

                if(chosenService.service_cca && chosenService.service_cca[0] && chosenService.service_cca[0].school_handling){
		            schoolHandling=true;
                    var schoolUser = Handlebars.compile(schoolUserTpl);
                    $('[title="new_user"]').html(schoolUser({service: chosenService.service_cca[0] }));
                    //alert('Cette école ne gère pas les inscriptions en ligne. Merci de contacter l\'école directement.');
                }
                // Check if Registrations are closed are not
                if(chosenService.registrations_closed){
                    $('#examsignup').hide();
                    $('#info_payment').hide();
                    $('div[title="new_user"]').hide();
                    $('div[title="custom_middle_data_scl_json"]').hide();
                    $('div[title="user_msg"]').hide();
                    var template = Handlebars.compile(registrationsClosed);
                    var finalHtml = template();
                    
                    $('#item').append(finalHtml);
                } else {

                    var source = priceTpl; 
                    frais =  (dataReceived.produit_info.filter(e => e.produit_info_id== frais_produit_info_id))[0];
                    retard_ttc =  (frais.item.filter(e => e.facturation_id == retard_facturation_id ))[0].taux_defaut_ttc;
                    envoi_ttc =  (frais.item.filter(e => e.facturation_id == envoi_facturation_id ))[0].taux_defaut_ttc;
                    envoi_intl_ttc =  (frais.item.filter(e => e.facturation_id == envoi_intl_facturation_id ))[0].taux_defaut_ttc;
                    var template = Handlebars.compile(priceTpl);

                    pricingData ={
                        service:chosenService,
                        frais : {
                            envoi : {
                                ttc: envoi_ttc,
                                facturation_id: envoi_facturation_id,
                                produit_info_id: frais_produit_info_id 
                            },
                            envoi_intl : {
                                ttc: envoi_intl_ttc,
                                facturation_id: envoi_intl_facturation_id,
                                produit_info_id: frais_produit_info_id 
                            },
                            retard : {
                                ttc: retard_ttc,
                                facturation_id: retard_facturation_id,
                                produit_info_id: frais_produit_info_id 
                            }
                        }
                    }

                    var finalHtml = template(pricingData);

                    $('#item').append(finalHtml);
                    initForm();
                    initBusinessLogic(pricingData);

                }
            }
        });
    });
}

function initForm(){

            //site_id:"7hF4xTpNzZZ4mSZth7m4Mn",
            //site_page_id:"10000000"

            /********************
              "account_id": "5R7BU2muu2zWB6MpUTjWjW",
              "clean_page_title": "Cours Hebdomadaires",
              "associated_skins_id": "geekschool",
              "msg_action_url": "msg_proxy.php?",
              "msg_web": "Votre message a bien \u00e9t\u00e9 envoy\u00e9",
              "json_validationFields": "[]",
              "base_uri" : "http://vm2/devconsole/sophiacloud/data_mgr.php?s=lead"
             ******************/

    // if we don't want to send regular way
    ///sclbasic.initFormulaire( form_config );

    $('button#examsignup').click(e=>{

        // avoid double/triple/quad... clicking
        if(clickedFlag){
            return;
        }
        clickedFlag = true;


        e.preventDefault();
        formdata = sclbasic.restFormSubmit.retrieveFormObject($('form#examform'));

        if(!validateForm(formdata)){ 
            clickedFlag = false;
            return; 
        }

        // add cd/item details
        formdata = enrichFormData(formdata);
        //console.log('toto');

        // make sure the currentFinalPrice is rounded to 2 digits for proper pay
        var affichPrecision = 100;
        currentFinalPrice =  Math.round(currentFinalPrice * affichPrecision) / affichPrecision;

        response_data.nom = formdata[0].new_user[0].nom; 
        response_data.prenom = formdata[0].new_user[0].prenom; 
        response_data.service = chosenService;
        response_data.amount = currentFinalPrice;

        sendForm({
            scl: formdata,
            amount : currentFinalPrice,
            service: chosenService
        });

    });

}

var templateModal = Handlebars.compile(modalTpl);
function validateForm(formData){

    var wrong_input = false;
    messages = [];
    if(
            !formdata[0].new_user
            || !formdata[0].new_user[0].nom 
      ){
        $('[name=nom]').addClass('is-invalid');
        wrong_input = true;
        messages.push("Nom");
    }

    if(
            !formdata[0].new_user
            || !formdata[0].new_user[0].prenom 
      ){
        $('[name=prenom]').addClass('is-invalid');
        wrong_input = true;
        messages.push("Prenom");
    }
    if(
        !schoolHandling && (
            !formdata[0].new_user
            || !formdata[0].new_user[0].email
        )
      ){
        $('[name=email]').addClass('is-invalid');
        wrong_input = true;
        messages.push("Email");
    }
    if(
        !schoolHandling && (
            !formdata[0].new_user
            || !formdata[0].new_user[0].tel1 
        )
      ){
        $('[name=tel1]').addClass('is-invalid');
        wrong_input = true;
        messages.push("Tel");
    }
    if(
            !formdata[0].custom_middle_data_scl_json
            || !formdata[0].custom_middle_data_scl_json[0].dob 
      ){
        $('[name=dob]').addClass('is-invalid');
        wrong_input = true;
        messages.push("Date de naissance");
    }

    if(wrong_input){
        var modalHtml = templateModal({
            wrong_input : messages 
        });
        $('#missingInputModal .modal-body').html(modalHtml);
        $('#missingInputModal').modal('show');
        return false;
    }
    return true;

}


function enrichFormData(formData){

    var this_code_tva;
    var item_list = 
        chosenService
        .contrat_detail_template[0]
        .item.map(function(i){
            this_code_tva = i.code_tva;
            return {
                facturation_id: i.facturation_id,
                produit_info_id: i.produit_info_id,
                quantite: i.quantite,
                code_tva: i.code_tva,
                ht: i.ht,
                taux: i.taux,
                ttc: i.ttc
            };
        });
    if(chosenRetard){
        let item = pricingData.frais.retard;
        item.quantite = 1;
        item.code_tva = this_code_tva;
        item.ht= item.taux = item.ttc/1.2;
        item_list.push(item);
    }
    if(chosenEnvoi){
        let item = pricingData.frais.envoi;
        item.quantite = 1;
        item.code_tva = this_code_tva;
        item.ht= item.taux = item.ttc/1.2;
        item_list.push(item);
    }
    if(chosenEnvoiIntl){
        let item = pricingData.frais.envoi_intl;
        item.quantite = 1;
        item.code_tva = this_code_tva;
        item.ht= item.taux = item.ttc/1.2;
        item_list.push(item);
    }


    formData[0].contrat_detail = [];
    formData[0].contrat_detail.push({
        total_ttc: currentFinalPrice,
        service : [{service_id:chosenService.service_id}],
        item : item_list 
    });
    return formData;
}

function addToFinalPrice(num){
    currentFinalPrice += num;
    $('#final_price').text(currentFinalPrice.toFixed(2));

}
function removeFromFinalPrice(num){
    currentFinalPrice -= num;
    $('#final_price').text(currentFinalPrice.toFixed(2));
}

function initBusinessLogic(pricingData){

    $('#late').hide();

    currentFinalPrice = pricingData.service.contrat_detail_template[0].total_ttc;

    var nowDate = new Date();
    var timeLimit = new Date(pricingData.service.service_cca[0].date_limite_inscription * 1000);
    // 17h UTC = 18h CET (heures d'ete = 19h)
    timeLimit.setUTCHours(17);

    if(nowDate >  timeLimit){
        addToFinalPrice(pricingData.frais.retard.ttc);
        $('#late').html('45.00 € Frais de retard pour inscription tardive ajouté<br />Date limite d\'inscription dépassé : '
                +sclbasic.util.intToDatetime(pricingData.service.service_cca[0].date_limite_inscription)
                +' - 18h00'
                );
        $('#late_price').text('Frais de retard : '+pricingData.frais.retard.ttc);
        chosenRetard =1;

        $('#late').show();
    }


    $('.mail_certificate').click(function(e){
        if($(this).prop('checked')){
            if($(this).attr('id') == 'mail_certificate_france'){
                if( $('#mail_certificate_intl').prop("checked")){
                    $('#mail_certificate_intl').prop("checked", false);
                    removeFromFinalPrice(pricingData.frais.envoi_intl.ttc);
                    chosenEnvoiIntl = null;
                }
                addToFinalPrice(pricingData.frais.envoi.ttc);
                chosenEnvoi =1;
                $('#send_price').text('Frais d\'envoi : '+pricingData.frais.envoi.ttc.toFixed(2));
            }
            if($(this).attr('id') == 'mail_certificate_intl'){
                if( $('#mail_certificate_france').prop("checked")){
                    $('#mail_certificate_france').prop("checked", false);
                    removeFromFinalPrice(pricingData.frais.envoi.ttc);
                    chosenEnvoi = null;
                }
                addToFinalPrice(pricingData.frais.envoi_intl.ttc);
                chosenEnvoiIntl =1;
                $('#send_price').text('Frais d\'envoi : '+pricingData.frais.envoi_intl.ttc.toFixed(2));
            }
        } else {
            removeFromFinalPrice(pricingData.frais.envoi.ttc);
            chosenEnvoi = null;
            $('#send_price').text('');
        }
    });

    chosenPayment = 'cb';
    $('#examsignup').show();
    $('#checkout_cb').show();
    $('#info_payment').show();


}



var templateResponse = Handlebars.compile(responseTpl);
var templateToPayment = Handlebars.compile(toPaymentTpl);
var templateToFwdMessage = Handlebars.compile(toFwdMessageTpl);
function sendForm(data){

    $('.ajax_loading').show();

    $.ajax( {
        dataType: null,
        contentType:'application/json',
        method: "POST",
        url: "/payment/send",
        data: JSON.stringify(data),
        mimeType: "application/json", // /!\ evite une erreur xml mal formé en local
        success: function ( server_res ) {
            $('.ajax_loading').fadeOut();
            response_data.server = server_res;
            if(server_res.scl && server_res.scl.contrat_detail){
                setTimeout(function(){
                    var params = {
                        skins : 'cca',
                        account_id : server_res.scl.contrat_detail[0].account_id,
                        contrat_list : server_res.scl.contrat_detail[0].contrat_detail_id,
                        fwd_pay :  Buffer.from(JSON.stringify({
                            'toto' : 1,
                            'message_html' : templateToFwdMessage(response_data),  
                        }), 'utf8').toString('base64')
                    }; 
                    var fullurl = config.payment_url+'?'+queryString.stringify(params);
                    window.location.href = fullurl; 
                },3000);
                response_data.contrat_valid = true;
            } 
            var toPaymentHtml = templateToPayment(response_data);
            $('#content').html(toPaymentHtml);
        },
        error : function ( jqXHR, textStatus, errorThrown ){
            $('.ajax_loading').fadeOut();
            var errorMsg = "Il y a une erreur. Merci d'envoyer une capture d'ecran sur bug@groupeset.com";
            $('#content').html(errorMsg);
        }
    } );

}




module.exports = {
    run: run
};
