
/// filter only wanted pages
if(['/','/exams-form'].indexOf(window.location.pathname) != -1){
    let page = window.location.pathname;
    if(window.location.pathname == '/'){
        page += 'home';
    }
    // remove slash
    page = page.substring(1);
    var pageModule = require('./pages/'+page);
    pageModule.run();
}

