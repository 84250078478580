var sclbasic = require('node-scl-basic')
var jQuery = $
global.$ = $; // rendre globale (on peut l'utiliser depuis la console F12)
global.jQuery = jQuery;

////////////// is in common normally .... to be refactored
var locSummaryObj = $.extend(true, {}, sclbasic.displayList.scl_fieldObject);
locSummaryObj.type = 'string';
locSummaryObj.getDisplayValue = function(rowdata) {
    return rowdata['destinataire'] + ' <br /> ' +
        rowdata['ville'];
};
locSummaryObj.getDisplayHtml = function(rowdata) {
    return '<span>' +
        this.getDisplayValue(rowdata) +
        '</span>';
};
sclbasic.displayList.fieldObject_dispatcher['location_summary'] = locSummaryObj;

////////////// Support - modalites_exam
var modaliteExamObj = $.extend(true, {}, sclbasic.displayList.scl_fieldObject);
modaliteExamObj.type = 'string';
modaliteExamObj.getDisplayValue = function(rowdata) {
    if(rowdata['modalites_exam'] == "CB"){
        return "Computer Based";
    } else if(rowdata['modalites_exam'] == "PB"){
        return "Paper Based";
    } else {
        return "";
    }   
};
modaliteExamObj.getDisplayHtml = function(rowdata) {
    return '<span>' +
        this.getDisplayValue(rowdata) +
        '</span>';
};
sclbasic.displayList.fieldObject_dispatcher['modalites_exam'] = modaliteExamObj;



////////////////// DATE EXAM
var dateExamObj = $.extend(true, {}, sclbasic.displayList.scl_fieldObject);
dateExamObj.type = 'numeric';
dateExamObj.getSortValue = function(rowdata) {
    return rowdata['date_exam'];
}
dateExamObj.getDisplayValue = function(rowdata) {
    return sclbasic.util.intToDatetime(rowdata['date_exam']);
}
sclbasic.displayList.fieldObject_dispatcher['date_exam'] = dateExamObj;

//////////////// DATE LIMITE
var dateLimiteObj = $.extend(true, {}, sclbasic.displayList.scl_fieldObject);
dateLimiteObj.type = 'numeric';
dateLimiteObj.getSortValue = function(rowdata) {
    return rowdata['date_limite_inscription'];
}
dateLimiteObj.getDisplayValue = function(rowdata) {
    return sclbasic.util.intToDatetime(rowdata['date_limite_inscription']);
}
sclbasic.displayList.fieldObject_dispatcher['date_limite_inscription'] = dateLimiteObj;



//////////////// SERVICE pour Liste Inscription Web
var serviceCCAWebObj = $.extend(true, {}, sclbasic.displayList.scl_fieldObject);
serviceCCAWebObj.type = 'string';
serviceCCAWebObj.getDisplayValue = function(rowdata) {
    return rowdata['description'];
}

serviceCCAWebObj.getDisplayHtml = function(rowdata) {
    return serviceCCAWebObj.getDisplayHtmlParametrized(rowdata, 'exams-form');
};

serviceCCAWebObj.getDisplayHtmlParametrized = function(rowdata, url) {
    var label = serviceCCAWebObj.getDisplayValue(rowdata) + ' - ' + sclbasic.util.intToDatetime(rowdata['date_exam']);
    var isServiceDisabled = "";
    var isFull = "";
    var addLabelInfo = ""; 
    var dateNow = new Date();
    var timeLimit = new Date(rowdata['date_limite_inscription'] * 1000);
    // 17h UTC = 18h CET (heures d'ete = 19h)
    timeLimit.setUTCHours(17);

    // Max Service Size
    if (
            rowdata['registrations_closed'] == "1" || 
            (
            rowdata['max_size'] &&
            rowdata['count'] &&
            parseInt(rowdata['count']) >= parseInt(rowdata['max_size'])
            )
    ) {
        isFull = " isFull";
        addLabelInfo = "<br>COMPLET";
    }

    // Max Service Size
    if (dateNow > timeLimit) {
        isServiceDisabled = " isDisabled";
        addLabelInfo += "<br>DATE LIMITE DEPASS&Eacute;E";
        isFull = "";
    }

    // Web Description for info free field display
    if(rowdata['web_description']){
        addLabelInfo += "<br>"+rowdata['web_description'];
    }

    /*var encoded_label = encodeURI(label);*/
    return '<a href="' + url + '?label=' +
        label +
        '&service_id=' +
        rowdata['service_id'] +
        '" class="btn btn-primary btn-large inscription ' + isServiceDisabled + isFull + '">  ' +
        serviceCCAWebObj.getDisplayValue(rowdata) + addLabelInfo
        '</a>';
};

sclbasic.displayList.fieldObject_dispatcher['service_cca_web'] = serviceCCAWebObj;

// Label Formulaire

var params = sclbasic.util.parseQueryString(window.location.search);
//var label = new RegExp('[\\&?]' + 'label' + '=([^&amp;#]*)').exec(window.location.href);
//var service_id = new RegExp('[\\&&amp;]' + 'service_id' + '=([^&amp;#]*)').exec(window.location.href);
if (typeof(params['label']) != 'undefined' && params['label'].length > 0) {
    $('#choice').text(decodeURIComponent(params['label']));
}
if (typeof(params['service_id']) != 'undefined' && params['service_id'].length > 0) {
    $('[name=service_id]').val(decodeURIComponent(params['service_id']));
}



// Lang Switcher

$('.fr_switch').click(function() {
    $("body").addClass("fr_version").removeClass("en_version");
});

$('.en_switch').click(function() {
    $("body").addClass("en_version").removeClass("fr_version");
});

var lang = new RegExp('[\\#&amp;]' + 'lang' + '=([^&amp;#]*)').exec(window.location.href);
if ($(lang).length > 0) {
    if (lang[1] == 'en') {
        $("body").addClass("en_version").removeClass("fr_version");
    }
}

// Confirm Inscription


$('.btn.inscription').on('click', function(e) {
    if ($('.confirm_conditions').attr('checked') != 'checked') {
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();
        $("html, body").animate({ scrollTop: 0 }, "slow");
        if ($('body').hasClass('fr_version')) {
            alert("Merci d'accepter les conditions d'inscription");
        } else if ($('body').hasClass('en_version')) {
            alert("Please accept subscription terms");
        }
    }
});

module.exports = {
    serviceCCAWebObj: serviceCCAWebObj
};