var $ = require( 'jquery' )
var jQuery = $
global.$ = $; // rendre globale (on peut l'utiliser depuis la console F12)
global.jQuery = jQuery;
require( 'bootstrap3' );
var sclbasic = require('node-scl-basic')
var cca = require('../cca');

function run(){
    $( document ).ready( function () {
        //alert('toto');
        //alert(sclbasic.displayList.scl_fieldObject.toString());

        $.ajax({
            type: 'GET',
            url: '/proxy/exams-feed?pretty',
            contentType:    'text/plain',
                // show loader
            beforeSend: function () {
                $('#divAjaxLoader').show();
            },
            complete: function(){
                $('#divAjaxLoader').hide();
            },
            error: function(){
                // borealis = no alert
                alert('Erreur Affichage');
                $('#divAjaxLoader').text('Erreur Affichage');
            },
            // parse response and apply searchCallback if non empty
            success: function(dataReceived){
                // deserialize response
                sclbasic
                    .displayList
                    .displayList(
                        $('table'),
                        {
                            uriOptions : {
                                q : 'exam_cca'
                            },
                            data : dataReceived 
                        }
                    );
            }
        });


    });
}

module.exports = {
    run: run
};
